import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  Form,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import DemoNavbar from "../../components/Navbars/DemoNavbar.js";
import CardsFooter from "../../components/Footers/CardsFooter.js";
import { Link } from "react-router-dom";
import Download from "../IndexSections/Download.js";
import { LanguageContext } from "../../settings/language.js";

const Landing = () => {
  const targetElementRef = useRef();
  const targetElementRef2 = useRef();
  const targetElementRef3 = useRef();
  const {LANGUAGE} = React.useContext(LanguageContext);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (targetElementRef.current) {
      targetElementRef.current.scrollTop = 0;
    }
  }, []);

  const navigateToElement = () => {
    if (targetElementRef.current) {
      targetElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigateToElement2 = () => {
    if (targetElementRef2.current) {
      targetElementRef2.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigateToElement3 = () => {
    if (targetElementRef3.current) {
      targetElementRef3.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <DemoNavbar navigateToElement={navigateToElement} />
      <main>
        <div className="position-relative">
          {/* shape Hero */}
          <section className="section section-lg section-shaped pb-250">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row style={{direction: LANGUAGE[0].id === 'ar' ? 'rtl' : 'ltr'}}>
                  <Col lg="7">
                    <h1 style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="display-3 text-darkGrey">
                      {LANGUAGE[0].Welcome_to_FarFera}{" "}
                    </h1>
                    <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="lead text-darkGrey">
                      {LANGUAGE[0].experience_a_new_era}
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-darkGrey"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
        </div>
        <section className="section section-lg pt-lg-0 mt--200">
          <Container>
            <Row style={{direction: LANGUAGE[0].id === 'ar' ? 'rtl' : 'ltr'}} className="justify-content-center">
              <Col lg="12">
                <Row className="row-grid">
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape icon-shape-darkGrey rounded-circle mb-4">
                          <img
                            className="iconn color-white"
                            alt="..."
                            src={"https://firebasestorage.googleapis.com/v0/b/le-pere.appspot.com/o/icons%2Fcustomer.png?alt=media&token=26344490-bfd1-4ce2-8714-a8dcd5f69d31"}
                          />
                        </div>
                        <h6 style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="text-primary text-uppercase">
                          {LANGUAGE[0].cliens}
                        </h6>
                        <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="text-darkGrey description mt-3">
                          {LANGUAGE[0].browse_a_wide_selection}
                        </p>
                        <Button
                          className="mt-4"
                          color="primary"
                          onClick={(e) => { e.preventDefault(); navigateToElement() }}
                        >
                          {LANGUAGE[0].learn_more}
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape icon-shape-darkGrey rounded-circle mb-4">
                          <img
                            className="iconn color-white"
                            alt="..."
                            src={"https://firebasestorage.googleapis.com/v0/b/le-pere.appspot.com/o/icons%2Fdriver.png?alt=media&token=6c0ae86a-55e6-451d-a505-b2ad83132631"}
                          />
                        </div>
                        <h6 style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="text-primary text-uppercase">
                        {LANGUAGE[0].drivers}
                        </h6>
                        <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="text-darkGrey description mt-3">
                        {LANGUAGE[0].become_a_values_member}
                        </p>
                        <Button
                          className="mt-4"
                          color="primary"
                          onClick={(e) => { e.preventDefault(); navigateToElement3() }}
                        >
                          {LANGUAGE[0].learn_more}
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape icon-shape-darkGrey rounded-circle mb-4">
                          <img
                            className="iconn color-white"
                            alt="..."
                            src={"https://firebasestorage.googleapis.com/v0/b/le-pere.appspot.com/o/icons%2Fstore.png?alt=media&token=d6e6d2b9-25a4-46bd-9fdd-fac915d14fdb"}
                          />
                        </div>
                        <h6 style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="text-primary text-uppercase">
                        {LANGUAGE[0].restaurants}
                        </h6>
                        <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="text-darkGrey description mt-3">
                        {LANGUAGE[0].expand_your_reach}
                        </p>
                        <Button
                          className="mt-4"
                          color="primary"
                          onClick={(e) => { e.preventDefault(); navigateToElement2() }}
                        >
                          {LANGUAGE[0].learn_more}
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg mt--100">
          <Container>
            <Row style={{direction: LANGUAGE[0].id === 'ar' ? 'rtl' : 'ltr'}} className="row-grid align-items-center">
              <Col className="order-md-2" md="6">
                <img
                  alt="..."
                  className="img-fluid floating"
                  src={require("../../assets/img/theme/promo-1.png")}
                />
              </Col>
              <Col className="order-md-1" md="6">
                <div className="pr-md-5">
                  <h3 style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className=' text-creamy'>{LANGUAGE[0].your_all_in_one}</h3>
                  <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className='text-default'>
                  {LANGUAGE[0].discover_a_world_of_convenience}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section bg-creamy">
          <Container>
            <Row style={{direction: LANGUAGE[0].id === 'ar' ? 'rtl' : 'ltr'}} className="row-grid align-items-center">
              <Col md="6">
                <Card className="bg-default shadow border-0">
                  <CardImg
                    alt="..."
                    src={require("../../assets/img/theme/promo-2.png")}
                    top
                  />
                  <blockquote className="card-blockquote">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="svg-bg"
                      preserveAspectRatio="none"
                      viewBox="0 0 583 95"
                    >
                      <polygon
                        className="fill-default"
                        points="0,52 583,95 0,95"
                      />
                      <polygon
                        className="fill-default"
                        opacity=".2"
                        points="0,42 583,95 683,0 0,95"
                      />
                    </svg>
                    <h4 style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="display-3 font-weight-bold text-white">
                    {LANGUAGE[0].design_system}
                    </h4>
                    <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="lead text-italic text-white">
                    {LANGUAGE[0].our_app_is_easy_to_use}
                    </p>
                  </blockquote>
                </Card>
              </Col>
              <Col md="6">
                <div className={LANGUAGE[0].id == 'ar' ? `pr-md-5` : `pl-md-5`}>
                  <h3 style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} ref={targetElementRef} id="farfera1" className="display-3 text-darkGrey">{LANGUAGE[0].try_farfera_today}</h3>
                  <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="lead text-darkGrey">
                  {LANGUAGE[0].transform_the_way_you_order}
                  </p>
                  <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="text-darkGrey">
                  {LANGUAGE[0].say_goodbye_to_delivery}
                  </p>
                  <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="text-darkGrey">
                  {LANGUAGE[0].farfera_is_more_than_an_app}
                  </p>
                  <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="text-darkGrey">
                  {LANGUAGE[0].so_why_wait_dowanload}
                  </p>
                  <Button
                    className="btn-neutral btn-icon"
                    color="darkGrey"
                    href="https://apps.apple.com/ma/app/farfera/id1667413095"
                    target="_blank"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fa fa-apple" />
                    </span>
                    <span className={`btn-inner--text ${LANGUAGE[0].id === 'ar' ? 'mr-1' : 'ml-1'}`}>
                    {LANGUAGE[0].available_on_app_store}
                    </span>
                  </Button>
                  <p></p>
                  <Button
                    className="btn-neutral btn-icon"
                    color="darkGrey"
                    href="https://play.google.com/store/apps/details?id=com.farfera.farfera"
                    target="_blank"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fa fa-play" />
                    </span>
                    <span className={`btn-inner--text ${LANGUAGE[0].id === 'ar' ? 'mr-1' : 'ml-1'}`}>
                      {LANGUAGE[0].available_on_google_play}
                    </span>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg bg-gradient-darkGrey">
          <Container className="py-md">
            <Row style={{direction: LANGUAGE[0].id === 'ar' ? 'rtl' : 'ltr'}} className="row-grid justify-content-between align-items-center">
              <Col lg="6">
                <h3 style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} ref={targetElementRef2} id="res" className="display-3 text-creamy">
                {LANGUAGE[0].become_a_partner}{" "}
                  <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="lead text-creamy"> {LANGUAGE[0].expand_your_reach_delight}</p>
                </h3>
                <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="text-default">
                {LANGUAGE[0].take_your_business_to_new_heights}
                </p>
                <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="text-default">
                {LANGUAGE[0].with_the_farfera_merchant_app}
                </p>
                <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="text-default">
                {LANGUAGE[0].dont_miss_out_on_the_opportunity}
                </p>
                <Button
                  className="btn-icon btn-3 ml-1"
                  color="primary"
                  href="https://apps.apple.com/ma/app/farfera-restaurant/id6448477902"
                  type="button"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fa fa-apple"/>
                  </span>
                  <span className={`btn-inner--text ${LANGUAGE[0].id === 'ar' ? 'mr-1' : 'ml-1'}`}>{LANGUAGE[0].available_on_app_store}</span>
                </Button>
                <p></p>
                <Button
                  className="btn-icon btn-3 ml-1"
                  color="primary"
                  href="https://play.google.com/store/apps/details?id=com.farfera.restaurant"
                  type="button"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fa fa-play" />
                  </span>
                  <span className={`btn-inner--text ${LANGUAGE[0].id === 'ar' ? 'mr-1' : 'ml-1'}`}>{LANGUAGE[0].available_on_google_play}</span>
                </Button>
              </Col>
              <Col className="mb-lg-auto" lg="5">
                <div className="transform-perspective-right">
                  <Card className="bg-secondary shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("../../assets/img/theme/promo-3.png")}
                      top
                    />
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-creamy"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        <section className="section bg-creamy">
          <Container>
            <Row style={{direction: LANGUAGE[0].id === 'ar' ? 'rtl' : 'ltr'}} className="row-grid align-items-center">
              <Col md="6">
                <Card className="bg-default shadow border-0">
                  <CardImg
                    alt="..."
                    src={require("../../assets/img/theme/promo-4.png")}
                    top
                  />
                </Card>
              </Col>
              <Col md="6">
                <div ref={targetElementRef3} id='driv' className={LANGUAGE[0].id === 'ar' ? "pr-md-5" : "pl-md-5"}>
                  <h3 style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="display-3 text-darkGrey">
                  {LANGUAGE[0].become_a_driver}{" "}
                    <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="lead text-darkGrey">{LANGUAGE[0].empower_your_journey}</p>
                  </h3>
                  <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="text-darkGrey">
                  {LANGUAGE[0].farfera_is_a_game_changing}
                  </p>
                  <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="text-darkGrey">
                  {LANGUAGE[0].one_of_the_key_features}
                  </p>
                  <p style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} className="text-darkGrey">
                  {LANGUAGE[0].farfera_is_the_ultimate_platform}
                  </p>
                  <Button
                    className="btn-neutral btn-icon"
                    color="darkGrey"
                    href="https://apps.apple.com/ma/app/farfera-driver/id6448494691"
                    target="_blank"
                  >
                    <span className="btn-inner--icon">
                      <i className="fa fa-apple mr-2" />
                    </span>
                    <span className={`nav-link-inner--text ${LANGUAGE[0].id === 'ar' ? 'mr-1' : 'ml-1'}`}>
                    {LANGUAGE[0].available_on_app_store}
                    </span>
                  </Button>
                  <p></p>
                  <Button
                    className="btn-neutral btn-icon"
                    color="darkGrey"
                    href="https://play.google.com/store/apps/details?id=com.farfera.driver"
                    target="_blank"
                  >
                    <span className="btn-inner--icon">
                      <i className="fa fa-play mr-2" />
                    </span>
                    <span className={`nav-link-inner--text ${LANGUAGE[0].id === 'ar' ? 'mr-1' : 'ml-1'}`}>
                    {LANGUAGE[0].available_on_google_play}
                    </span>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg bg-gradient-default">
            <Container>
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">{LANGUAGE[0].the_furture_of_delivery_with_farfera}</h2>
                  <p className="lead text-white">
                  {LANGUAGE[0].at_farfera_we_are_on_a_mission}
                  </p>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-darkGrey"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
      </main>
      <CardsFooter />
    </>
  );
};

export default Landing;