import React, { useEffect } from "react";
import { Button, Card, Container, Row, Col } from "reactstrap";
import DemoNavbar from "../../components/Navbars/DemoNavbar.js";
import SimpleFooter from "../../components/Footers/SimpleFooter.js";
import { LanguageContext } from "../../settings/language.js";

const ContactUs = () => {
  const {LANGUAGE} = React.useContext(LanguageContext);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainRef.current) {
      mainRef.current.scrollTop = 0;
    }
  }, []);

  const mainRef = React.createRef();

  return (
    <>
      <DemoNavbar />
      <main className="profile-page" ref={mainRef}>
        <section className="section-profile-cover section-shaped my-0">
          {/* Circles background */}
          <div className="shape shape-style-1 shape-default alpha-4">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-darkGrey" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
        <section className="section">
          <Container>
            <Card className="card-profile shadow mt--300">
              <div className="px-4">

                <Row className="justify-content-center">

                  <Col className="order-lg-2" lg="3">

                    <div className="card-profile-image">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={require("../../assets/img/theme/logo_farfera.png")}
                        />
                      </a>
                    </div>
                  </Col>
                  <Col
                    className="order-lg-3 text-lg-right align-self-lg-center"
                    lg="4"
                  >
                    <div className="card-profile-actions py-4 mt-lg-0">
                    </div>
                  </Col>
                  <Col className="order-lg-1" lg="4">
                    <div className="card-profile-stats d-flex justify-content-center">
                    </div>
                  </Col>
                </Row>
                <div className="text-center pt-100">
                  <h3 className="display-3 text-darkGrey">
                  {LANGUAGE[0].farfera_contact}
                  </h3>
                  <div className="h6 font-weight-300 text-creamy">
                    <i className="ni location_pin mr-2" />
                    {LANGUAGE[0].nador_morocco}
                  </div>
                  <p className="text-darkGrey">
                  {LANGUAGE[0].reach_out_to_us_for_any_inquiries}
                  </p>
                </div>
                <Container className="pt-50 pb-100">
                  <Row className="row-grid mt-5">
                    <Col lg="4">
                      <div className="icon icon-lg icon-shape bg-gradient-creamy shadow rounded-circle text-darkGrey">
                        <i className="ni ni-building text-darkGrey" />
                      </div>
                      <h5 className="text-darkGrey mt-3">{LANGUAGE[0].address}</h5>
                      <p className="text-creamy">
                      {LANGUAGE[0].find_us_at_the_heart}
                      </p>
                      <p className="text-white mt-3">
                        {LANGUAGE[0].headquaters}:
                        District Elkhattabbi Street 6th November
                        Number 14, Nador, Morocco, 62000
                      </p>
                    </Col>
                    <Col lg="4">
                      <div className="icon icon-lg icon-shape bg-gradient-creamy shadow rounded-circle text-darkGrey">
                        <i className="ni ni-headphones text-darkGrey" />
                      </div>
                      <h5 className="text-darkGrey mt-3">{LANGUAGE[0].phone}</h5>
                      <p className="text-creamy">
                      {LANGUAGE[0].call_us_anytime_for_assistance}
                      </p>
                      <p className="text-white mt-3">
                        +212 682 543 714
                      </p>
                    </Col>
                    <Col lg="4">
                      <div className="icon icon-lg icon-shape bg-gradient-creamy shadow rounded-circle text-darkGrey">
                        <i className="ni ni-email-83 text-darkGrey" />
                      </div>
                      <h5 className="text-darkGrey mt-3">{LANGUAGE[0].email}</h5>
                      <p className="text-creamy">
                      {LANGUAGE[0].drop_us_a_message}
                      </p>
                      <p className="text-white mt-3">
                        farfera@farfera.com
                      </p>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Card>
          </Container>
        </section>
      </main>
      <SimpleFooter />
    </>
  );
};

export default ContactUs;
