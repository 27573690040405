import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Headroom from "headroom.js";
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import { LanguageContext } from "../../settings/language";

const DemoNavbar = ({ navigateToElement }) => {
  const {LANGUAGES, LANGUAGE, toggleL} = React.useContext(LanguageContext);
  const [collapseClasses, setCollapseClasses] = useState("");
  const [collapseOpen, setCollapseOpen] = useState(false);
  useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    headroom.init();
  }, []);

  const onExiting = () => {
    setCollapseClasses("collapsing-out");
  };

  const onExited = () => {
    setCollapseClasses("");
  };

  return (
    <>
      <header className="header-global">
        <Navbar
          style={{direction: LANGUAGE[0].id === 'ar' ? 'rtl' : 'ltr'}}
          className="navbar-main navbar-transparent navbar-light headroom"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <NavbarBrand className={`${LANGUAGE[0].id === 'ar' ? 'ml-lg' : 'mr-lg'} ss`} to="/" tag={Link}>
              <img
                style={{float: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}}
                alt="..."
                src={require("../../assets/img/brand/FarFera_full.png")}
              />
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse
              toggler="#navbar_global"
              navbar
              className={collapseClasses}
              onExiting={onExiting}
              onExited={onExited}
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link style={{float: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} to="/">
                      <img
                        alt="..."
                        src={require("../../assets/img/brand/FarFera_full.png")}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button style={{float: LANGUAGE[0].id === 'ar' ? 'left' : 'right'}} className="navbar-toggler" id="navbar_global">
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="navbar-nav-hover align-items-lg-center" style={{paddingRight: 0}} navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle style={{float: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} to="/landing-page" tag={Link} nav>
                    <i className={`ni ni-ui-04 d-lg-none ${LANGUAGE[0].id === 'ar' ? 'ml-1' : 'mr-1'}`} />
                    <span className="nav-link-inner--text">{LANGUAGE[0].home}</span>
                  </DropdownToggle>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav>
                  <DropdownToggle style={{float: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} to="/contactUs" tag={Link} nav>
                    <i className={`ni ni-collection d-lg-none ${LANGUAGE[0].id === 'ar' ? 'ml-1' : 'mr-1'}`} />
                    <span className="nav-link-inner--text">{LANGUAGE[0].contact_us}</span>
                  </DropdownToggle>
                </UncontrolledDropdown>
              </Nav>
              <Nav className={`align-items-lg-center ${LANGUAGE[0].id === 'ar' ? 'mr-lg-auto' : 'ml-lg-auto'}`} style={{paddingRight: 0}} navbar>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="https://www.facebook.com/profile.php?id=100092609424561&mibextid=LQQJ4d"
                    id="tooltip333589074"
                    target="_blank"
                    style={{float: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}}
                  >
                    <i className="fa fa-facebook-square" />
                    <span className={`nav-link-inner--text d-lg-none ${LANGUAGE[0].id === 'ar' ? 'mr-2' : 'ml-2'}`}>
                      Facebook
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip333589074">
                    {LANGUAGE[0].Like_us_on_Facebook}
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="https://instagram.com/farfera_ma?igshid=OGQ5ZDc2ODk2ZA=="
                    id="tooltip356693867"
                    target="_blank"
                    style={{float: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}}
                  >
                    <i className="fa fa-instagram" />
                    <span className={`nav-link-inner--text d-lg-none ${LANGUAGE[0].id === 'ar' ? 'mr-2' : 'ml-2'}`}>
                      Instagram
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip356693867">
                    {LANGUAGE[0].Follow_us_on_Instagram}
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="https://twitter.com/farfera_ma"
                    id="tooltip184698705"
                    target="_blank"
                    style={{float: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}}
                  >
                    <i className="fa fa-twitter-square" />
                    <span className={`nav-link-inner--text d-lg-none ${LANGUAGE[0].id === 'ar' ? 'mr-2' : 'ml-2'}`}>
                      Twitter
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip184698705">
                  {LANGUAGE[0].Follow_us_on_Twitter}
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem className="d-none d-lg-block ml-lg-4">
                  <Button
                    className="btn-neutral btn-icon"
                    color="darkGrey"
                    onClick={navigateToElement}
                  >
                    <span className="btn-inner--icon">
                      <i className={`fa fa-cloud-download ${LANGUAGE[0].id === 'ar' ? 'ml-2' : 'mr-2'}`} />
                    </span>
                    <span className="nav-link-inner--text ml-1">{LANGUAGE[0].download}</span>
                  </Button>
                </NavItem>
              </Nav>
              <Nav className="navbar-nav-hover align-items-lg-center" style={{paddingRight: 0}} navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle style={{float: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}} nav>
                    <i className={`fa fa-language d-lg-none ${LANGUAGE[0].id === 'ar' ? 'ml-1' : 'mr-1'}`} />
                    <span className="nav-link-inner--text" style={{justifyContent: 'center', alignItems: 'center'}} >
                      {LANGUAGE[0].name}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    {LANGUAGES.filter(x => x.id !== LANGUAGE[0].id).map(lg => (
                      <DropdownItem style={{justifyContent: 'center', alignItems: 'center'}} key={lg.id} onClick={() => {toggleL(lg.id)}}>
                        {lg.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default DemoNavbar;