import React, { useEffect } from "react";
import { Button, Card, Container, Row, Col } from "reactstrap";
import DemoNavbar from "../../components/Navbars/DemoNavbar.js";
import SimpleFooter from "../../components/Footers/SimpleFooter.js";
import { LanguageContext } from "../../settings/language.js";

const QrContent = () => {
  const {LANGUAGE} = React.useContext(LanguageContext);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainRef.current) {
      mainRef.current.scrollTop = 0;
    }
  }, []);

  const mainRef = React.createRef();

  return (
    <>
      <DemoNavbar />
      <main className="profile-page" ref={mainRef}>
        <section className="section-profile-cover section-shaped my-0">
          {/* Circles background */}
          <div className="shape shape-style-1 shape-default alpha-4">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-darkGrey" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
        <section className="section">
          <Container>
            <Card className="card-profile shadow mt--300">
              <div className="px-4">

                <Row className="justify-content-center">

                  <Col className="order-lg-2" lg="3">

                    <div className="card-profile-image">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={require("../../assets/img/theme/logo_farfera.png")}
                        />
                      </a>
                    </div>
                  </Col>
                  <Col
                    className="order-lg-3 text-lg-right align-self-lg-center"
                    lg="4"
                  >
                    <div className="card-profile-actions py-4 mt-lg-0">
                    </div>
                  </Col>
                  <Col className="order-lg-1" lg="4">
                    <div className="card-profile-stats d-flex justify-content-center">
                    </div>
                  </Col>
                </Row>
                <div className="text-center pt-100">
                  <h3 className="display-3 text-darkGrey">
                  {LANGUAGE[0].Welcome_to_FarFera}
                  </h3>
                </div>
                <Container className="pt-50 pb-100">
                  <Row className="row-grid mt-5 ">
                  <Col lg="4">
                      <div className="icon icon-lg icon-shape bg-gradient-creamy shadow rounded-circle text-darkGrey">
                        <i className="fa fa-cloud-download text-darkGrey" />
                      </div>
                      <h5 className="text-darkGrey mt-3">{LANGUAGE[0].download}</h5>
                      <Button
                        className="btn-neutral btn-icon"
                        color="darkGrey"
                        href="https://apps.apple.com/ma/app/farfera/id1667413095"
                        target="_blank"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fa fa-apple" />
                        </span>
                        <span className={`btn-inner--text ${LANGUAGE[0].id === 'ar' ? 'mr-1' : 'ml-1'}`}>
                        {LANGUAGE[0].available_on_app_store}
                        </span>
                      </Button>
                      <p></p>
                      <Button
                        className="btn-neutral btn-icon"
                        color="darkGrey"
                        href="https://play.google.com/store/apps/details?id=com.farfera.farfera"
                        target="_blank"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fa fa-play" />
                        </span>
                        <span className={`btn-inner--text ${LANGUAGE[0].id === 'ar' ? 'mr-1' : 'ml-1'}`}>
                          {LANGUAGE[0].available_on_google_play}
                        </span>
                      </Button>
                    </Col>
                    <Col lg="4">
                      <div className="icon icon-lg icon-shape bg-gradient-creamy shadow rounded-circle text-darkGrey">
                        <i className="ni ni-world-2 text-darkGrey" />
                      </div>
                      <h5 className="text-darkGrey mt-3">{LANGUAGE[0].visit_our_website}</h5>
                      <Button
                        className="btn-neutral btn-icon"
                        color="darkGrey"
                        href="https://farfera.com"
                        target="_blank"
                      >
                        <span className={`btn-inner--text ${LANGUAGE[0].id === 'ar' ? 'mr-1' : 'ml-1'}`}>
                            www.farfera.com
                        </span>
                      </Button>
                      <p></p>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Card>
          </Container>
        </section>
      </main>
      <SimpleFooter />
    </>
  );
};

export default QrContent;
