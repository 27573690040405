import React from "react";

const languages = [
    {
        id: 'en',
        select_your_language: 'Select your language',
        language: 'Language',
        name: 'English',
        home: 'Home',
        contact_us: 'Contact Us',
        download: 'Download',
        Like_us_on_Facebook: 'Like us on Facebook',
        Follow_us_on_Instagram: 'Follow us on Instagram',
        Follow_us_on_Twitter: 'Follow us on Twitter',
        Welcome_to_FarFera: 'Welcome to FarFera',
        experience_a_new_era: "Experience a new era of convenient delivery. Elevate your dining with FarFera's experience connecting clients, drivers, and restaurants.",
        cliens: "Clients",
        drivers: 'Drivers',
        restaurants: 'Restaurants',
        browse_a_wide_selection: "Browse a wide selection of delicious meals, explore diverse cuisines, and conveniently place your orders with just a few clicks. Enjoy the ease and convenience of FarFera's intuitive platform designed to satisfy your cravings.",
        become_a_values_member: "Become a valued member of our dedicated driver community. Earn flexible income, set your own schedule, and deliver delightful meals to our hungry clients. Experience the freedom and flexibility of driving with FarFera.",
        expand_your_reach: "Expand your reach, increase your revenue, and gain access to a wider customer base by partnering with FarFera. Join our network of esteemed restaurants and showcase your culinary expertise to discerning customers.",
        learn_more: "Learn More",
        your_all_in_one: "Your All-In-One Delivery Solution",
        discover_a_world_of_convenience: "Discover a world of convenience, where mouthwatering meals meet seamless delivery. Whether you're a hungry client, an aspiring driver, or a restaurant looking to expand, FarFera connects you to an extraordinary dining experience. Join us today and indulge in the ultimate convenience of on-demand delivery.",
        try_farfera_today: "Try FarFera Today!",
        transform_the_way_you_order: "Transform the way you order food and groceries, forever. With FarFera, you can now connect with merchants and drivers in real-time, making the entire delivery process seamless and hassle-free.",
        say_goodbye_to_delivery: "Say goodbye to delivery frustrations. With FarFera, enjoy timely and transparent deliveries, empowered by cutting-edge technology. Track your order in real-time, communicate directly with your driver, and experience hassle-free service. Whether you're a busy professional, a parent with a packed schedule, or simply someone who enjoys the convenience of ordering from home, FarFera has got you covered.",
        farfera_is_more_than_an_app: "FarFera is more than an app – it's a platform that builds relationships, connecting merchants, drivers, and clients. We believe that building strong relationships is key to building a successful business, and that's why we've designed FarFera to be more than just a transactional tool",
        so_why_wait_dowanload: "So why wait? Download FarFera today and experience the future of food and grocery delivery. We promise you won't be disappointed!",
        design_system: "Design System",
        our_app_is_easy_to_use: "Our app is easy to use, with a user-friendly interface that makes ordering food and groceries a breeze.",
        become_a_partner: "Become a Partner",
        expand_your_reach_delight: "Expand Your Reach, Delight More Customers",
        take_your_business_to_new_heights: "Take your business to new heights with the FarFera merchant app. Designed to empower merchants, our app offers a comprehensive suite of tools and features to streamline your operations, increase efficiency, and attract more customers.",
        with_the_farfera_merchant_app: "With the FarFera merchant app, you gain full control over your orders, inventory, and customer interactions. Seamlessly manage incoming orders and efficiently process them with just a few taps. Stay on top of order statuses, ensuring timely preparation and delivery. Whether you're running a restaurant, retail store, or any other business, our app provides the perfect solution to simplify your daily operations.",
        dont_miss_out_on_the_opportunity: "Don't miss out on the opportunity to transform your business. Download the FarFera merchant app today and unlock a world of possibilities. Elevate your operations, attract more customers, and take your business to new heights with FarFera.",
        available_on_google_play: "Available on Google Play",
        available_on_app_store: "Available on App Store",
        become_a_driver: "Become a Driver",
        empower_your_journey: "Empower Your Journey, Earn on Your Terms",
        farfera_is_a_game_changing: "FarFera is a game-changing delivery partner app that offers unparalleled opportunities for drivers to earn and grow their business. With flexible schedules, real-time updates, and exciting incentives, FarFera provides a unique platform for delivery partners to achieve success on their own terms.",
        one_of_the_key_features: "One of the key features of FarFera is its user-friendly interface, which makes it easy for drivers to navigate and manage their deliveries. The app provides real-time updates on orders, enabling drivers to stay on top of their deliveries and ensure timely drop-offs. In addition, FarFera offers a range of delivery options, from food and groceries to packages and more, giving drivers the flexibility to choose the type of deliveries that work best for them.",
        farfera_is_the_ultimate_platform: "FarFera is the ultimate platform for delivery partners looking to take their business to the next level. Sign up today and join the ride to success with FarFera!",
        the_furture_of_delivery_with_farfera: "The Future of Delivery with FarFera",
        at_farfera_we_are_on_a_mission: "At FarFera, we're on a mission to revolutionize the way you dine and connect. With our cutting-edge technology, seamless experiences, and a thriving community of clients, drivers, and restaurants, we're redefining the delivery landscape. Say goodbye to the frustrations of delayed orders and lack of transparency. Embrace a new era of convenience, where delicious meals are just a few taps away. Join us today and discover the joy of effortless delivery, meaningful connections, and a world of culinary delights. Welcome to FarFera, where exceptional dining experiences come to life.",
        thank_you_for_supporting_us: "Thank you for supporting us!",
        lets_get_in_touch_on_any_of_these_platforms: "Let's get in touch on any of these platforms.",
        terms:"Terms",
        blog:"Blog",
        farfera_contact: "FarFera Contact",
        reach_out_to_us_for_any_inquiries: "Reach out to us for any inquiries, feedback, or partnership opportunities. Our team is ready to assist you and provide the best possible support. We look forward to hearing from you!",
        nador_morocco: "Nador, Morocco",
        address: "Address",
        find_us_at_the_heart: "Find us at the heart of the city.",
        headquaters: "Headquarters",
        phone: "Phone",
        call_us_anytime_for_assistance: "Call us anytime for assistance.",
        email: "Email",
        drop_us_a_message: "Drop us a message and we'll respond promptly.",
        visit_our_website: "Visit our website",
    },
    {
        id: 'fr',
        select_your_language: 'Sélectionnez votre langue',
        language: 'Langue',
        name: 'Français',
        home: 'Accueil',
        contact_us: 'Contactez-nous',
        download: 'Télécharger',
        Like_us_on_Facebook: 'Suivez-nous sur Facebook',
        Follow_us_on_Instagram: 'Suivez-nous sur Instagram',
        Follow_us_on_Twitter: 'Suivez-nous sur Twitter',
        Welcome_to_FarFera : "Bienvenue chez FarFera",
        experience_a_new_era: "Vivez une nouvelle ère de livraison pratique. Rehaussez votre expérience culinaire avec l'expérience de FarFera qui relie les clients, les chauffeurs et les restaurants.",
        cliens: "Clients",
        drivers: "Chauffeurs",
        restaurants: "Restaurants",
        browse_a_wide_selection: "Parcourez une large sélection de délicieux repas, explorez des cuisines diverses et passez facilement vos commandes en quelques clics seulement. Profitez de la simplicité et de la commodité de la plateforme intuitive de FarFera conçue pour satisfaire vos envies.",
        become_a_values_member: "Devenez un membre valorisé de notre communauté de chauffeurs dévoués. Gagnez un revenu flexible, définissez votre propre emploi du temps et livrez des repas délicieux à nos clients affamés. Découvrez la liberté et la flexibilité de conduire avec FarFera.",
        expand_your_reach: "Élargissez votre portée, augmentez vos revenus et accédez à une base de clients plus vaste en partenariat avec FarFera. Rejoignez notre réseau de restaurants prestigieux et mettez en valeur votre expertise culinaire auprès de clients exigeants.",
        learn_more: "En savoir plus",
        your_all_in_one: "Solution de livraison tout-en-un",
        discover_a_world_of_convenience: "Découvrez un monde de commodité, où des repas alléchants rencontrent une livraison fluide. Que vous soyez un client affamé, un chauffeur en herbe ou un restaurant cherchant à s'agrandir, FarFera vous connecte à une expérience culinaire extraordinaire. Rejoignez-nous dès aujourd'hui et profitez de l'ultime confort de la livraison à la demande.",
        try_farfera_today: "Essayez FarFera dès aujourd'hui !",
        transform_the_way_you_order: "Transformez la manière dont vous commandez de la nourriture et des courses, pour toujours. Avec FarFera, vous pouvez désormais vous connecter avec les commerçants et les chauffeurs en temps réel, rendant ainsi tout le processus de livraison fluide et sans tracas.",
        say_goodbye_to_delivery: "Dites adieu aux frustrations liées à la livraison. Avec FarFera, profitez de livraisons ponctuelles et transparentes, propulsées par une technologie de pointe. Suivez votre commande en temps réel, communiquez directement avec votre chauffeur et bénéficiez d'un service sans soucis. Que vous soyez un professionnel occupé, un parent avec un emploi du temps chargé ou simplement quelqu'un qui apprécie la commodité de commander depuis chez vous, FarFera répond à vos besoins.",
        farfera_is_more_than_an_app: "FarFera est bien plus qu'une application - c'est une plateforme qui construit des relations en connectant les commerçants, les chauffeurs et les clients. Nous croyons que des relations solides sont la clé d'une entreprise réussie, c'est pourquoi nous avons conçu FarFera pour être bien plus qu'un simple outil transactionnel.",
        so_why_wait_dowanload: "Alors, pourquoi attendre ? Téléchargez FarFera dès aujourd'hui et découvrez l'avenir de la livraison de nourriture et d'épicerie. Nous vous promettons que vous ne serez pas déçu !",
        design_system: "Système de conception",
        our_app_is_easy_to_use: "Notre application est facile à utiliser, avec une interface conviviale qui rend la commande de nourriture et d'épicerie un jeu d'enfant.",
        become_a_partner: "Devenez un partenaire",
        expand_your_reach_delight: "Élargissez votre portée et ravissez plus de clients",
        take_your_business_to_new_heights: "Faites évoluer votre entreprise vers de nouveaux sommets avec l'application marchande FarFera. Conçue pour autonomiser les commerçants, notre application offre une gamme complète d'outils et de fonctionnalités pour rationaliser vos opérations, augmenter l'efficacité et attirer plus de clients.",
        with_the_farfera_merchant_app: "Avec l'application marchande FarFera, vous avez un contrôle total sur vos commandes, votre inventaire et vos interactions avec les clients. Gérez facilement les commandes entrantes et traitez-les efficacement en quelques clics. Restez informé de l'état de vos commandes, garantissant une préparation et une livraison rapides. Que vous dirigiez un restaurant, un magasin de détail ou toute autre entreprise, notre application offre la solution idéale pour simplifier vos opérations quotidiennes.",
        dont_miss_out_on_the_opportunity: "Ne manquez pas l'occasion de transformer votre entreprise. Téléchargez dès aujourd'hui l'application marchande FarFera et débloquez un monde de possibilités. Élevez vos opérations, attirez plus de clients et faites évoluer votre entreprise avec FarFera.",
        available_on_google_play: "Disponible sur Google Play",
        available_on_app_store: "Disponible sur l'App Store",
        become_a_driver: "Devenez un chauffeur",
        empower_your_journey: "Autonomisez votre parcours, gagnez selon vos conditions",
        farfera_is_a_game_changing: "FarFera est une application partenaire de livraison révolutionnaire qui offre des opportunités inégalées aux chauffeurs pour gagner de l'argent et développer leur activité. Avec des horaires flexibles, des mises à jour en temps réel et des incitations excitantes, FarFera offre une plateforme unique pour que les partenaires de livraison réussissent selon leurs propres conditions.",
        one_of_the_key_features: "Une des principales caractéristiques de FarFera est son interface conviviale, qui facilite la navigation et la gestion des livraisons pour les chauffeurs. L'application fournit des mises à jour en temps réel sur les commandes, permettant aux chauffeurs de rester informés de l'état de leurs livraisons et de garantir des livraisons rapides. En outre, FarFera propose une gamme d'options de livraison, des repas et des courses aux colis et bien plus encore, offrant aux chauffeurs la flexibilité de choisir le type de livraisons qui leur convient le mieux.",
        farfera_is_the_ultimate_platform: "FarFera est la plateforme ultime pour les partenaires de livraison cherchant à propulser leur activité vers de nouveaux sommets. Inscrivez-vous aujourd'hui et rejoignez l'aventure vers le succès avec FarFera !",
        the_furture_of_delivery_with_farfera: "L'avenir de la livraison avec FarFera",
        at_farfera_we_are_on_a_mission: "Chez FarFera, nous sommes en mission pour révolutionner la manière dont vous vous restaurez et vous connectez. Grâce à notre technologie de pointe, nos expériences fluides et une communauté florissante de clients, de chauffeurs et de restaurants, nous redéfinissons le paysage de la livraison. Dites adieu aux frustrations liées aux commandes retardées et au manque de transparence. Embrassez une nouvelle ère de commodité, où des repas délicieux ne sont qu'à quelques clics. Rejoignez-nous dès aujourd'hui et découvrez la joie d'une livraison sans effort, de connexions significatives et d'un monde de délices culinaires. Bienvenue chez FarFera, où les expériences gastronomiques exceptionnelles prennent vie.",
        thank_you_for_supporting_us: "Merci de nous soutenir !",
        lets_get_in_touch_on_any_of_these_platforms: "Restons en contact sur l'une de ces plateformes.",
        terms: "Conditions",
        blog: "Blog",
        farfera_contact: "Contactez FarFera",
        reach_out_to_us_for_any_inquiries: "Contactez-nous pour toute question, commentaire ou opportunité de partenariat. Notre équipe est prête à vous aider et à vous fournir le meilleur soutien possible. Nous sommes impatients de vous entendre !",
        nador_morocco: "Nador, Maroc",
        address: "Adresse",
        find_us_at_the_heart: "Retrouvez-nous au cœur de la ville.",
        headquaters: "Siège social",
        phone: "Téléphone",
        call_us_anytime_for_assistance: "Appelez-nous à tout moment pour obtenir de l'aide.",
        email: "E-mail",
        drop_us_a_message: "Envoyez-nous un message et nous vous répondrons rapidement.",
        visit_our_website: "Visitez notre site web",
     },
    {
        id: 'ar',
        select_your_language: 'اختر لغتك',
        language: 'اللغة',
        name: 'العربية',
        home: 'الصفحة الرئيسية',
        contact_us: 'اتصل بنا',
        download: 'تحميل',
        Like_us_on_Facebook: 'تابعنا على الفيسبوك',
        Follow_us_on_Instagram: 'تابعنا على انستجرام',
        Follow_us_on_Twitter: 'تابعنا على تويتر',
        Welcome_to_FarFera: 'مرحباً بكم في فارفيرا',
        experience_a_new_era: "عش تجربة جديدة لتوصيل سهل ومريح. ارتقِ بتجربتك في تناول الطعام مع تجربة فارفيرا الراقية التي تربط العملاء والسائقين والمطاعم.",
        cliens: "العملاء",
        drivers: "السائقين",
        restaurants: "المطاعم",
        browse_a_wide_selection: "تصفّح مجموعة واسعة من الوجبات اللذيذة، استكشف المطابخ المتنوعة وقم بطلب طعامك بسهولة بضغطات قليلة. استمتع بالسهولة والراحة التي يقدمها منصة فارفيرا المبتكرة لتلبية رغباتك.",
        become_a_values_member: "كن عضوًا قيمًا في مجتمع السائقين المخصص لدينا. اكسب دخلًا مرنًا، وحدد جدولك الزمني الخاص، وقدم وجبات لذيذة لعملائنا. اختبر حرية ومرونة القيادة مع فارفيرا.",
        expand_your_reach: "قم بتوسيع نطاقك، وزد دخلك، واحصل على وصول إلى قاعدة عملاء أوسع من خلال الشراكة مع فارفيرا. انضم إلى شبكتنا من المطاعم المرموقة وعرض خبرتك الطهوية للعملاء الذين يميزون.",
        learn_more: "تعرف أكثر",
        your_all_in_one: "حلاً واحدًا لكل احتياجات التوصيل",
        discover_a_world_of_convenience: "اكتشف عالمًا من الراحة، حيث تلتقي الوجبات اللذيذة مع التوصيل السلس. سواء كنت عميلاً، أو سائقًا طموحًا، أو مطعمًا يتطلع للتوسع، تربطك فارفيرا بتجربة تناول طعام استثنائية. انضم إلينا اليوم وتمتع بأقصى درجات الراحة في التوصيل حسب الطلب.",
        try_farfera_today: "جرّب فارفيرا اليوم!",
        transform_the_way_you_order: "حوّل طريقة طلب الطعام والبقالة إلى الأبد. مع فارفيرا، يمكنك الآن التواصل مع التجار والسائقين في الوقت الحقيقي، مما يجعل عملية التوصيل بأكملها سلسة وخالية من المتاعب.",
        say_goodbye_to_delivery: "قل وداعًا لإحباطات التوصيل. مع فارفيرا، استمتع بتوصيلات في الوقت المناسب وشفافة، بفضل التكنولوجيا المتطورة. تتبع طلبك في الوقت الحقيقي، وتواصل مباشرة مع السائق الخاص بك، واستمتع بخدمة خالية من المتاعب. سواء كنت محترفًا مشغولًا أو والدين لديهم جدول زمني مزدحم، أو ببساطة شخص يستمتع بسهولة الطلب من المنزل، فإن فارفيرا ستكون بجانبك.",
        farfera_is_more_than_an_app: "فارفيرا هو أكثر من تطبيق - إنه منصة تبني العلاقات وتربط التجار والسائقين والعملاء. نحن نؤمن بأن بناء علاقات قوية هو المفتاح لبناء عمل ناجح، ولهذا السبب صممنا فارفيرا ليكون أكثر من مجرد أداة معاملات.",
        so_why_wait_dowanload: "فلماذا تنتظر؟ حمّل فارفيرا اليوم واستمتع بمستقبل توصيل الطعام والبقالة. نعدك أنك لن تخيب ظنك!",
        design_system: "نظام التصميم",
        our_app_is_easy_to_use: "تطبيقنا سهل الاستخدام، مع واجهة مستخدم سهلة تجعل طلب الطعام والبقالة أمرًا سهلاً.",
        become_a_partner: "كن شريكًا",
        expand_your_reach_delight: "وسع نطاق عملك واستمتع بمزيد من العملاء",
        take_your_business_to_new_heights: "قم بتطوير عملك إلى آفاق جديدة مع تطبيق فارفيرا للتجار. يهدف تطبيقنا إلى تمكين التجار ويقدم مجموعة شاملة من الأدوات والميزات لتبسيط العمليات وزيادة الكفاءة وجذب المزيد من العملاء.",
        with_the_farfera_merchant_app: "مع تطبيق فارفيرا للتجار، تحصل على السيطرة الكاملة على طلباتك والمخزون والتفاعل مع العملاء. يمكنك إدارة الطلبات الواردة بسلاسة ومعالجتها بفعالية ببضع نقرات فقط. تابع حالات الطلب، مما يضمن التحضير والتوصيل في الوقت المناسب. سواء كنت تدير مطعمًا أو متجر بيع بالتجزئة أو أي نوع آخر من الأعمال التجارية، فإن تطبيقنا يوفر الحل المثالي لتبسيط عملياتك اليومية.",
        dont_miss_out_on_the_opportunity: "لا تفوت الفرصة لتحويل عملك. حمّل تطبيق فارفيرا للتجار اليوم واكتشف عالمًا من الإمكانيات. ارتق بعملياتك، وجذب المزيد من العملاء، وأعمل على رفع مستوى عملك مع فارفيرا.",
        available_on_google_play: "متوفر على متجر Google Play",
        available_on_app_store: "متوفر على متجر App Store",
        become_a_driver: "كن سائقًا",
        empower_your_journey: "قم بتمكين رحلتك واكسب بأفضل الشروط",
        farfera_is_a_game_changing: "فارفيرا هو تطبيق شريك في التوصيل الذي يغير اللعبة ويوفر فرصًا لا مثيل لها للسائقين لكسب المال وتطوير عملهم. مع جداول زمنية مرنة وتحديثات في الوقت الحقيقي وحوافز مثيرة، يقدم فارفيرا منصة فريدة لشركاء التوصيل لتحقيق النجاح بمزاياهم الخاصة.",
        one_of_the_key_features: "أحد الميزات الرئيسية لفارفيرا هو واجهته السهلة الاستخدام، مما يجعل من السهل على السائقين التنقل وإدارة توصيلاتهم. يوفر التطبيق تحديثات في الوقت الحقيقي حول الطلبات، مما يتيح للسائقين البقاء على اطلاع على توصيلاتهم وضمان التسليم في الوقت المحدد. بالإضافة إلى ذلك، يقدم فارفيرا مجموعة من خيارات التوصيل، من الطعام والبقالة إلى الطرود وغيرها، مما يمنح السائقين المرونة لاختيار نوع التوصيل الذي يناسبهم بشكل أفضل.",
        farfera_is_the_ultimate_platform: "فارفيرا هي المنصة النهائية لشركاء التوصيل الذين يسعون إلى الارتقاء بأعمالهم إلى مستوى جديد. سجل اليوم وانضم إلى رحلة النجاح مع فارفيرا!",
        the_furture_of_delivery_with_farfera: "مستقبل التوصيل مع فارفيرا",
        at_farfera_we_are_on_a_mission: "في فارفيرا، نحن في مهمة لتحويل طريقة تناول الطعام والتواصل. من خلال تكنولوجيا متطورة وتجارب سلسة ومجتمع نابض بالحياة من العملاء والسائقين والمطاعم، نعيد تعريف المشهد التوصيلي. قل وداعًا لإحباطات الطلبات المتأخرة وعدم الشفافية. انضم إلينا اليوم واكتشف متعة التوصيل السهل، والاتصالات المعنوية، وعالم من الأطباق اللذيذة. مرحبًا بك في فارفيرا، حيث تتحقق تجارب الطعام الاستثنائية.",
        thank_you_for_supporting_us: "شكرًا لدعمكم!",
        lets_get_in_touch_on_any_of_these_platforms: "لنتواصل على أي من هذه المنصات.",
        terms: "الشروط",
        blog: "المدوّنة",
        farfera_contact: "اتصل بـ فرفيرا",
        reach_out_to_us_for_any_inquiries: "تواصل معنا لأية استفسارات أو ملاحظات أو فرص شراكة. فريقنا مستعد لمساعدتك وتقديم الدعم الأفضل الممكن. نحن نتطلع للتواصل معك!",
        nador_morocco: "الناظور، المغرب",
        address: "العنوان",
        find_us_at_the_heart: "ابحث عنا في قلب المدينة.",
        headquaters: "المقر الرئيسي",
        phone: "الهاتف",
        call_us_anytime_for_assistance: "اتصل بنا في أي وقت للحصول على المساعدة.",
        email: "البريد الإلكتروني",
        drop_us_a_message: "أرسل لنا رسالة وسنرد عليك في أقرب وقت ممكن.",
        visit_our_website: "زر موقعنا",
     }
]

const initialState = {
    lang: 'en',
    LANGUAGE: languages.filter(item => {return item.id === 'en'}),
    LANGUAGES: languages.filter(item => {return item.id !== null}),
    toggleL: () => {}
  }

let LanguageContext = React.createContext(initialState)

function LanguageProvider({ children }) {
    const [lang, setLang] = React.useState('en') // Default theme is light
    
    // To toggleL between dark and light modes
    let toggleL = (item) => {
       console.log('woooow', item)
       setLang(item)
    }
    
    
    // Filter the styles based on the theme selected
    const LANGUAGE = languages.filter(item => {return item.id === lang})
    const LANGUAGES = languages.filter(item => {return item.id !== null})

    return (
        <LanguageContext.Provider value={{LANGUAGE, lang, toggleL, LANGUAGES}}>
           {children}
        </LanguageContext.Provider>
        )
    }
export { LanguageProvider, LanguageContext }