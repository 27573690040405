import React from "react";
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../settings/language";

const SimpleFooter = () => {
  const {LANGUAGE} = React.useContext(LanguageContext);
  return (
    <>
      <footer className="footer bg-darkGrey">
        <Container>
          <Row className=" row-grid align-items-center mb-5">
            <Col lg="6">
              <h3 style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}}  className=" text-creamy font-weight-light mb-2">
              {LANGUAGE[0].thank_you_for_supporting_us}
              </h3>
              <h4 style={{textAlign: LANGUAGE[0].id === 'ar' ? 'right' : 'left'}}  className="text-default mb-0 font-weight-light">
              {LANGUAGE[0].lets_get_in_touch_on_any_of_these_platforms}
              </h4>
            </Col>
            <Col className="text-lg-center btn-wrapper" lg="6">
              <Button
                className="btn-icon-only rounded-circle"
                color="twitter"
                href="https://twitter.com/farfera_ma"
                id="tooltip475038074"
                target="_blank"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-twitter" />
                </span>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip475038074">
              {LANGUAGE[0].Follow_us_on_Twitter}
              </UncontrolledTooltip>
              <Button
                className="btn-icon-only rounded-circle ml-1"
                color="facebook"
                href="https://www.facebook.com/profile.php?id=100092609424561&mibextid=LQQJ4d"
                id="tooltip837440414"
                target="_blank"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-facebook-square" />
                </span>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip837440414">
              {LANGUAGE[0].Like_us_on_Facebook}
              </UncontrolledTooltip>
              <Button
                className="btn-icon-only rounded-circle ml-1"
                color="dribbble"
                href="https://instagram.com/farfera_ma?igshid=OGQ5ZDc2ODk2ZA=="
                id="tooltip829810202"
                target="_blank"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-instagram" />
                </span>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip829810202">
              {LANGUAGE[0].Follow_us_on_Instagram}
              </UncontrolledTooltip>
            </Col>
          </Row>
          <hr />
          <Row className=" align-items-center justify-content-md-between">
            <Col md="6">
              <div className="text-default copyright">
                © {new Date().getFullYear()}{" "}
                <a
                  href="https://farfera.com/"
                  target="_blank"
                >
                  FarFera
                </a>
                .
              </div>
            </Col>
            <Col md="6">
              <Nav className=" nav-footer justify-content-end">
                <NavItem>
                  <NavLink
                    href="https://farfera.com/"
                    target="_blank"
                  >
                    FarFera
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://farfera.com/"
                    target="_blank"
                  >
              {LANGUAGE[0].blog}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/contactUs"
                    tag={Link} 
                    target="_blank"
                  >
              {LANGUAGE[0].contact_us}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/Terms" 
                    tag={Link} 
                    target="_blank"
                  >
              {LANGUAGE[0].terms}
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default SimpleFooter;
