import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";

import Index from "./views/Index";
import Landing from "./views/examples/Landing";
import ContactUs from "./views/examples/ContactUs"
import QrContent from "./views/examples/QrContent"
import Terms from "./views/examples/Terms";
import { LanguageProvider } from "./settings/language";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <LanguageProvider>
  <BrowserRouter>
    <Switch>
      <Route
        path="/"
        exact
        render={(props) => <Landing {...props} />}
      />
      <Route
        path="/contactUs"
        exact
        render={(props) => <ContactUs {...props} />}
      />
      <Route
        path="/QrContent"
        exact
        render={(props) => <QrContent {...props} />}
      />
      <Route
        path="/Terms"
        exact
        render={(props) => <Terms {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
  </LanguageProvider>
);
